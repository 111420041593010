import React, { useState } from 'react';

const PricingPlan = props => {
  const [toggle, setToggle] = useState('monthly');
  return (
    <div className="pricing-plans-wrapper">
      <div className="pricing-toggler-section">
        <p
          className={`toggler-monthly  ${toggle === 'monthly' ? 'active' : ''}`}
        >
          Billed monthly
        </p>
        <div className="toggler-wrap">
          <label className="switch">
            <input type="checkbox" />
            <span
              className="toggler round"
              onClick={() => {
                toggle === 'monthly'
                  ? setToggle('annually')
                  : setToggle('monthly');
              }}
            ></span>
          </label>
        </div>
        <p
          className={`toggler-annually  ${
            toggle === 'annually' ? 'active' : ''
          }`}
        >
          Billed annually (saving 10%)
        </p>
      </div>
      <div className={`pricing-plans ${toggle}`}>
        <div className="pricing-plan monthly">
          <h2>Basic</h2>
          <p className="pricing-cost">
            <span className="dollar">$</span>250
          </p>
          <p className="pricing-frequency">per month</p>
          <div className="pricing-plan-features">
            <p>
              Upto&nbsp;
              <strong>1 million&nbsp;</strong>API requests per month
            </p>
          </div>
          <a
            className="pricing-plan-button"
            href="https://developer.triniti.ai/register"
          >
            30 days free trial
          </a>
        </div>
        <div className="pricing-plan monthly">
          <h2>
            Standard
            <span className="best-choice">Recommended</span>
          </h2>
          <p className="pricing-cost">
            <span className="dollar">$</span>1,200
          </p>
          <p className="pricing-frequency">per month</p>
          <div className="pricing-plan-features">
            <p>
              Upto&nbsp;
              <strong>1 million&nbsp;</strong>API requests per month
            </p>
            <p>
              <strong>0.0015</strong>&nbsp;per API beyond 1 million API requests
            </p>
          </div>
          <a
            className="pricing-plan-button"
            href="https://developer.triniti.ai/register"
          >
            30 days free trial
          </a>
        </div>
        <div className="pricing-plan monthly">
          <h2>Premium</h2>
          <p className="pricing-cost">
            <span className="dollar">$</span>2,500
          </p>
          <p className="pricing-frequency">per month</p>
          <div className="pricing-plan-features">
            <p>
              Upto&nbsp;
              <strong>1 million&nbsp;</strong>API requests per month
            </p>
            <p>
              <strong>0.0025</strong>&nbsp;per API beyond 1 million API requests
            </p>
          </div>
          <a
            className="pricing-plan-button"
            href="https://developer.triniti.ai/register"
          >
            30 days free trial
          </a>
        </div>
        <div className="pricing-plan annually">
          <h2>Basic</h2>
          <p className="pricing-cost">
            <span className="dollar">$</span>225
          </p>
          <p className="pricing-frequency">per month</p>
          <div className="pricing-plan-features">
            <p>
              Upto&nbsp;
              <strong>1 million&nbsp;</strong>API requests per month
            </p>
          </div>
          <a
            className="pricing-plan-button"
            href="https://developer.triniti.ai/register"
          >
            30 days free trial
          </a>
        </div>
        <div className="pricing-plan annually">
          <h2>Standard</h2>
          <p className="pricing-cost">
            <span className="dollar">$</span>1,080
          </p>
          <p className="pricing-frequency">per month</p>
          <div className="pricing-plan-features">
            <p>
              Upto&nbsp;
              <strong>1 million&nbsp;</strong>API requests per month
            </p>
            <p>
              <strong>0.0015</strong>&nbsp;per API beyond 1 million API requests
            </p>
          </div>
          <a
            className="pricing-plan-button"
            href="https://developer.triniti.ai/register"
          >
            30 days free trial
          </a>
        </div>
        <div className="pricing-plan annually">
          <h2>Premium</h2>
          <p className="pricing-cost">
            <span className="dollar">$</span>2,250
          </p>
          <p className="pricing-frequency">per month</p>
          <div className="pricing-plan-features">
            <p>
              Upto&nbsp;
              <strong>1 million&nbsp;</strong>API requests per month
            </p>
            <p>
              <strong>0.0025</strong>&nbsp;per API beyond 1 million API requests
            </p>
          </div>
          <a
            className="pricing-plan-button"
            href="https://developer.triniti.ai/register"
          >
            30 days free trial
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;

import React from 'react';

const PricingChart = props => {
  return (
    <div className="pricing-chart">
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>Basic</th>
            <th>Standard</th>
            <th>Premium</th>
          </tr>
          <tr>
            <td>
              Small Talk
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Casual conversation with your customers
                </span>
              </span>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Spell Checker
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Next generation spell checkers which automatically correct
                  user conversations
                </span>
              </span>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Channels - Basic
              <br />
              (Web, Mobile, Social)
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Conversational channels for Web, Mobile and Social Messaging
                  e.g Facebook Messenger, Telegram etc
                </span>
              </span>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Self Learning
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Supervised Self Learning from customer conversations
                </span>
              </span>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Customer Reports
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Customer reports with conversation history
                </span>
              </span>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Conversational Workflow Nodes
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Workflow nodes or steps in Conversation Workflows
                </span>
              </span>
            </td>
            <td>Upto 50 nodes</td>
            <td>Upto 100 nodes</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td>
              Conversation Context
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Follow on Conversational Context e.g "how do i pay my credit
                  card" followed by "what is the fees"
                </span>
              </span>
            </td>
            <td>No</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Analytics
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  In depth analytics on Customers, Conversations and AI
                </span>
              </span>
            </td>
            <td>90 days</td>
            <td>180 days</td>
            <td>1 year</td>
          </tr>
          <tr>
            <td>
              Domain Ontology
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Domain specific Knowledge graph which enables guided journeys
                  and Disambiguation support in case of ambiguity. Support for
                  Retail Banking domain currently.
                </span>
              </span>
            </td>
            <td>No</td>
            <td>Pre built</td>
            <td>Pre built</td>
          </tr>
          <tr>
            <td>
              Disambiguation
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Capability of the virtual agent to ask a customer for
                  clarifications in case of ambiguous questions. Supported only
                  if a Domain Ontology is enabled.
                </span>
              </span>
            </td>
            <td>No</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              Failover to Human Agent
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Transfer to a human agent in case the Virtual Assistant is not
                  able to answer customer queries
                </span>
              </span>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              QnA per Workspace
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Number of questions and answers allowed under a workspace
                </span>
              </span>
            </td>
            <td>1,000</td>
            <td>4,000</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td>
              Team Size
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Number of team members which can collaborate on a workspace
                </span>
              </span>
            </td>
            <td>4</td>
            <td>20</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td>
              API Limits
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Allowed API Limits per 15 min windows
                </span>
              </span>
            </td>
            <td>1,000 calls / 15 mins</td>
            <td>4,000 calls / 15 mins</td>
            <td>10,000 calls / 15 mins</td>
          </tr>
          <tr>
            <td>
              Test Hours
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Number of Test Hours allowed for Automated Batch Testing for a
                  workspace per month
                </span>
              </span>
            </td>
            <td>48</td>
            <td>120</td>
            <td>240</td>
          </tr>
          <tr>
            <td>
              Training Cycles
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Number of training cycles for a workspace per month
                </span>
              </span>
            </td>
            <td>20</td>
            <td>50</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td>
              Versions
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Historical Data Versions supported per workspace
                </span>
              </span>
            </td>
            <td>4</td>
            <td>10</td>
            <td>20</td>
          </tr>
          <tr>
            <td>
              Data Isolation
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  Type of procesing nodes i.e Shared and Dedicated
                </span>
              </span>
            </td>
            <td>Shared</td>
            <td>Shared</td>
            <td>Dedicated processing &amp; AI nodes</td>
          </tr>
          <tr>
            <td>
              SLA
              <span className="pricing-tooltip"></span>
              <span className="pricing-tooltip-answer-wrapper">
                <span className="pricing-tooltip-answer">
                  SLA Levels supported
                </span>
              </span>
            </td>
            <td>99</td>
            <td>99.5</td>
            <td>99.8</td>
          </tr>
          <tr>
            <td> Support Plans</td>
            <td>Community Forum</td>
            <td>Email</td>
            <td>Choice of premium plans</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PricingChart;

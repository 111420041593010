import React from 'react';

const StandardBanner = props => {
  return (
    <header className="standard-banner">
      <h1>Pricing</h1>
      <p>Transparent and flexible pricing which scales as you grow</p>
    </header>
  );
};

export default StandardBanner;
